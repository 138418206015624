// This allows the page to set a "current topic" which shows up in the header and
// tweaks the links in the navbar (to allow continuity in navigation).

'use client'

import React, { createContext, useContext, useState, ReactNode, useCallback, useRef } from 'react';
import { DisplayConceptRef } from '@lib/api';

interface TopicContextType {
  topic: DisplayConceptRef | null;
  setTopic: (topic: DisplayConceptRef | null) => void;
}

const TopicContext = createContext<TopicContextType | undefined>(undefined);

function useTopicState(): TopicContextType {
  const [topic, setTopicState] = useState<DisplayConceptRef | null>(null);
  const topicRef = useRef<DisplayConceptRef | null>(null);

  const setTopic = useCallback((newTopic: DisplayConceptRef | null) => {
    if (JSON.stringify(newTopic) !== JSON.stringify(topicRef.current)) {
      topicRef.current = newTopic;
      setTopicState(newTopic);
    }
  }, []);

  return { topic, setTopic };
}

export function TopicProvider({ children }: { children: ReactNode }) {
  const topicState = useTopicState();

  return (
    <TopicContext.Provider value={topicState}>
      {children}
    </TopicContext.Provider>
  );
}

export function useTopicContext() {
  const context = useContext(TopicContext);
  if (context === undefined) {
    throw new Error('useTopicContext must be used within a TopicProvider');
  }
  return context;
}
