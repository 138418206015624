'use client';

import React, { useState, useEffect, useRef, useCallback } from "react";
import Link from 'next/link';
import { FaSearch } from 'react-icons/fa';
import { useParams, useRouter, useSelectedLayoutSegment } from "next/navigation"
import { SelectItem, TopicSelector } from './TopicSelector';
import { useTopicContext } from '@context/TopicContext';
import { useUserDetails } from '@context/UserDetailsContext';
import { API_BASE_URL, WS_API_BASE_URL } from '@/app/common';

// Only needed for Header<>, keep with if you factor those components out
import '@/styles/headerfooter.css';
import '@/styles/topicselector.css'

export function Header() {
    enum Buttons {
        Home = "Home",
        Guide = "Guide",
        Talk = "Talk",
        Shop = "Shop",
        Unknown = "Unknown",
    }

    const segment = useSelectedLayoutSegment(); // This gets the active layout segment
    const params = useParams(); // This gets the dynamic route parameters
    const { topic, setTopic } = useTopicContext();
    const [activeButton, setActiveButton] = useState<Buttons>(Buttons.Unknown);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const topicSelectorRef = useRef<HTMLDivElement>(null);
    const searchContainerRef = useRef<HTMLDivElement>(null);

    const url_arg = topic ? '/' + encodeURIComponent(topic.cref) : '';

    const { userDetails } = useUserDetails();

    useEffect(() => {
        if (isSearching && topicSelectorRef.current) {
            const input = topicSelectorRef.current.querySelector('input');
            if (input) {
                input.focus();
            }
        }
    }, [isSearching]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
                setIsSearching(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
      function segmentToButton(segment: string | null): Buttons {
          switch (segment) {
              case null:
                  return Buttons.Home;
              case "guide":
                  return Buttons.Guide;
              case "docs":
              case "doc":
                  return Buttons.Talk;
              case "topicproducts":
              case "products":
                  return Buttons.Shop;
              default:
                  return Buttons.Unknown;
          }
      }

      let newActiveButton = segmentToButton(segment);
      setActiveButton(newActiveButton);
      if (params && params.id) {
        /*
        
        Currently the topic is set by individual pages in the TopicContext.  An
        alternative would be to look at the id component here and turn that into
        a CDef to render.  That would require an extra call to the server,
        though; also that call is hard to do inside a useEffect.  So for now we
        set button status by looking at the router segment here, but set topic
        by a callback via context.

        // This is a dumb assumption, that if we have an ID component that looks
        // like a cref, then it's the topic.  It would be better to have some
        // centralized place to define routes and paths and their meaning. 
        const maybe_cref = decodeURIComponent(
            Array.isArray(params.id) ?  params.id[0] : params.id);
        if (maybe_cref.startsWith('@') || maybe_cref.startsWith('/')) {
          const cref = maybe_cref;
          // Need to look up cdef for this cref, construct a DisplayConceptRef
          // out of it, and then set it as the topic here in the header.
        }

        */
      }
    }, [segment, params, Buttons]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSearch = (event: React.MouseEvent<HTMLElement>) => {
        // Always toggle search when clicking on the topic title
        setIsSearching(!isSearching);
    };

    const router = useRouter();

    const handleTopicSelect = useCallback((selectedItem: SelectItem) => {
        setTopic({
            cref: selectedItem.value,
            display: selectedItem.label
        });
        setIsSearching(false);

        const newTopic = encodeURIComponent(selectedItem.value);
        switch (activeButton) {
            case Buttons.Guide:
                router.push(`/guide/${newTopic}`);
                break;
            case Buttons.Talk:
                router.push(`/docs/${newTopic}`);
                break;
            case Buttons.Shop:
                router.push(`/topicproducts/${newTopic}`);
                break;
            default:
                // Also applies to Home location.
                // Go to guides as the default
                router.push(`/guide/${newTopic}`);
                break;
        }
    }, [setTopic, activeButton, router, Buttons]);

    return ( userDetails ?
    // Logged in headers
    <header className="page-header">
        <nav className="top-nav">
            <div className="title">
                <h1 className="header-logo">metamynd</h1>
            </div>
            <div className="buttonbar">
              <ul className="buttonlist">
                  <li><Link href="/" className={activeButton === Buttons.Home ? 'active' : ''}>Home</Link></li>
                  <li><Link href={`/guide${url_arg}`} className={activeButton === Buttons.Guide ? 'active' : ''}>Guide</Link></li>
                  <li><Link href={`/docs${url_arg}`} className={activeButton === Buttons.Talk ? 'active' : ''}>Talk</Link></li>
                  <li><Link href={`/topicproducts${url_arg}`} className={activeButton === Buttons.Shop ? 'active' : ''}>Shop</Link></li>
                  <li>
                      <button onClick={toggleMenu} className="hamburger-button">☰</button>
                      {isMenuOpen && (
                          <div className="popup-menu">
                              <Link href="/account" onClick={toggleMenu}>Account</Link>
                              <Link href="/citizens" onClick={toggleMenu}>Citizens</Link>
                              <Link href={`/admin/schema/${encodeURIComponent(topic?.cref || '')}`} onClick={toggleMenu}>Debug</Link>
                              <Link href="/help" onClick={toggleMenu}>Help</Link>
                              <Link href="/logout" onClick={toggleMenu}>Logout</Link>
                          </div>
                      )}
                  </li>
              </ul>
            </div>
        </nav>
        <div className="topic-container" ref={searchContainerRef}>
            <button onClick={toggleSearch} className="search-button">
                <FaSearch />
            </button>
            <div className="topic-selector-container" onClick={toggleSearch}>
                {isSearching || !topic ? (
                    <div ref={topicSelectorRef}>
                        <TopicSelector instanceId='header-topic-selector' onChangeFn={handleTopicSelect} />
                    </div>
                ) : (
                    <div className="topic-selected">
                        {topic.display}
                    </div>
                )}
            </div>
        </div>
    </header>
    :
    // Not logged in headers
    <header className="page-header">
        <nav className="top-nav">
            <div className="title">
                <h1>invite only</h1>
            </div>
            <div className="buttonbar">
                <ul className="buttonlist">
                    <li><Link href="/login">Login</Link></li>
                    <li><Link href="/register">Register</Link></li>
                </ul>
            </div>
        </nav>
    </header>
    );
}

export function Footer() {
    const { userDetails } = useUserDetails();
    return (
        <div className="page-footer">
            {userDetails && userDetails.username && (
                <>
                <span className="logo-font">© 2024 metamynd</span>
                <span className="logo-font"> Logged in as: {userDetails.username}</span>
                </>
            )}
        </div>
    );
}
